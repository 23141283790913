<template>
<div class="welcome flex-column">
        <div class="welcome-head flex-row-between mb10">
            <h2>最近更新</h2>
            <router-link class="welcome-head-more"  :to="{ name: 'myComic' }">查看全部</router-link>
        </div>
        <main class="welcome-content" v-scrollbar>
            <template v-if="tableList.length">
                <div class="welcome-table flex-row" v-empty="noData">
                    <comicCard v-for="card in tableList" :key="card.comicId" :list.sync="tableList" :comicData="formatPublicClass(card)"></comicCard>
                </div>
                <div class="comic-dispatch-line"></div>
            </template>
            <ul class="welcome-routes flex-row">
                <li v-for="item in routeList" :key="item.name" @click="routeMenu(item)">
                    <h3 class="welcome-routes-title">{{item.name}}</h3>
                </li>
            </ul>
        </main>
        <comic-publish
            @refreshData="refreshData"
            v-if="currentPublishComicData"
            :comicData.sync="currentPublishComicData"
            :platformList="platformList"
        ></comic-publish>
    </div>
</template>

<script>
import comicPublish from '@/components/comic/comicPublish';
import { comicMixin, paginationMixin } from '@/mixins';
export default {
    mixins: [comicMixin, paginationMixin],
    components: { comicPublish },
    data() {
        return {
            platformList: null, // 平台列表
            noData: true,
            pagination: {
                pageSize: 2
            },
            tableList: [],
            routeList: [
                { name: '新增漫画', routeName: 'addComic' },
                { name: '个人中心', routeName: 'personalMessage' },
                { name: '帮助中心', routeName: 'help' }
            ],
            currentPublishComicData: null, // 当前点击发布的章节信息
            selectComicList: [] // 选择的漫画
        };
    },
    created() {
        this.getComicPlatformList();
        this.getPublicClass().then(res => (this.publicClass = res));
        this.getTableList();
    },
    methods: {
        formatPublicClass(comicData) {
            const comicInfo = {
                ...comicData,
                ...this.$utils.formatPublicClass(this.publicClass[0].child, comicData.classIdlist.split(',')),
            }
            return comicInfo
        },
        // 获取漫画平台列表
        getComicPlatformList() {
            return this.$api('getComicPlatformList').then(({ data }) => {
                this.platformList = data.map((platform) => {
                    return {
                        platformId: platform.id,
                        name: platform.name,
                        edit: false,
                        selectTypeId: null,
                        publishTime: null
                    };
                });
            });
        },
        // 刷新界面数据，用于改变发布信息后刷新
        refreshData(comicData) {
            this.getTableList().then(() => {
                const newComic = this.tableList.find(item => item.comicId === comicData.comicId);
                this.currentPublishComicData = newComic;
            });
        },
        // 查询列表
        getTableList() {
            return this.$api('getComicList', { queryType: 'all', ...this.pagination }).then(res => {
                const { rows, pageIndex, pageSize, total } = res.data;
                this.noData = (rows.length !== 0);
                this.tableList = rows;
                this.pagination.pageIndex = pageIndex;
                this.pagination.pageSize = pageSize;
                this.pagination.total = total;
            });
        },
        // 展示发布dialog
        showDialog(comicData) {
            if (this.platformList === null || this.platformList.length === 0) {
                this.$message('平台列表尚未加载完成，请稍后点击');
                return;
            }
            this.currentPublishComicData = this.$utils.cloneDeep(comicData);
        },
        routeMenu({ routeName }) {
            this.$router.push({ name: routeName });
        }
    }
};
</script>

<style lang="scss">
.welcome{
    height: 100%;
    overflow: hidden;
    &-head{
        margin-bottom: 10px;
        height: 40px;
        line-height: 40px;
        h2{
            font-size: 24px;
        }
        &-more{
            display: block;
            font-size: 14px;
            color: #909399;
        }
    }
    &-content{
        flex-grow: 1;
        position: relative;
        overflow: hidden;
        padding-bottom: 20px;
        box-sizing: border-box;
    }
    &-table{
        overflow: hidden;
    }
    &-routes{
        li{
            position: relative;
            width: 160px;
            height: 160px;
            padding: 10px;
            border: 1px solid #e4e7ea;
            margin-right: 20px;
            background-color: #fff;
            text-align: center;
            cursor: pointer;
        }
        &-title{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 20px;
            font-weight: normal;
        }
    }
    &-publish{
        padding: 20px;
        .el-checkbox-group{
            flex-flow: row wrap;
            .el-checkbox{
                min-width: 100px;
            }
        }
        .el-checkbox{
            margin: 0 30px 10px 0;
        }
    }
}
</style>
